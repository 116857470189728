/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      applicationNumber
      phone
      email
      shippingStreetAddress
      shippingStreetAddress2
      shippingCity
      shippingState
      shippingZip
      notes {
        items {
          id
          note
          files
          author
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      contract {
        id
        dealer {
          id
          name
          email
          phone
          contact_person
          createdAt
          updatedAt
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        customer {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        typeNew
        tier
        termLength
        deductible
        contractPrice
        seniorCitizenMilitary
        originalInServiceDate
        originalWarrantyMonths
        reviewed
        approved
        customerSignature
        dealerSignature
        createdAt
        updatedAt
      }
      welcomeLetterSent
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerNote = /* GraphQL */ `
  query GetCustomerNote($id: ID!) {
    getCustomerNote(id: $id) {
      id
      customer {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      note
      files
      author
      authorID
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerNotes = /* GraphQL */ `
  query ListCustomerNotes(
    $filter: ModelCustomerNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        note
        files
        author
        authorID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDealer = /* GraphQL */ `
  query GetDealer($id: ID!) {
    getDealer(id: $id) {
      id
      name
      email
      phone
      contact_person
      contracts {
        items {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDealers = /* GraphQL */ `
  query ListDealers(
    $filter: ModelDealerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDealers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        contact_person
        contracts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      id
      VIN
      style
      make
      vehicleModel
      chassisModel
      vehicleYear
      purchasePrice
      purchaseDate
      lienholder
      owner {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      contract {
        id
        dealer {
          id
          name
          email
          phone
          contact_person
          createdAt
          updatedAt
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        customer {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        typeNew
        tier
        termLength
        deductible
        contractPrice
        seniorCitizenMilitary
        originalInServiceDate
        originalWarrantyMonths
        reviewed
        approved
        customerSignature
        dealerSignature
        createdAt
        updatedAt
      }
      claims {
        items {
          id
          approved
          reviewed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          dealer {
            id
            name
          }
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      dealer {
        id
        name
        email
        phone
        contact_person
        contracts {
          nextToken
        }
        createdAt
        updatedAt
      }
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      typeNew
      tier
      termLength
      deductible
      contractPrice
      seniorCitizenMilitary
      originalInServiceDate
      originalWarrantyMonths
      reviewed
      approved
      customerSignature
      dealerSignature
      createdAt
      updatedAt
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dealer {
          id
          name
          email
          phone
          contact_person
          createdAt
          updatedAt
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        customer {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        typeNew
        tier
        termLength
        deductible
        contractPrice
        seniorCitizenMilitary
        originalInServiceDate
        originalWarrantyMonths
        reviewed
        approved
        customerSignature
        dealerSignature
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClaim = /* GraphQL */ `
  query GetClaim($id: ID!) {
    getClaim(id: $id) {
      id
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      approved
      reviewed
      notes {
        items {
          id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listClaims = /* GraphQL */ `
  query ListClaims(
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        approved
        reviewed
        notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClaimNote = /* GraphQL */ `
  query GetClaimNote($id: ID!) {
    getClaimNote(id: $id) {
      id
      claim {
        id
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        approved
        reviewed
        notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const listClaimNotes = /* GraphQL */ `
  query ListClaimNotes(
    $filter: ModelClaimNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaimNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        claim {
          id
          approved
          reviewed
          createdAt
          updatedAt
        }
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
