import React, { useEffect, useState } from "react";
import Amplify, { Auth, API } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import Pdf from "react-to-pdf";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import moment from "moment";
import cs from "classnames";

import logo from "../../../assets/img/esw-logo.png";

const FormCell = ({ title, value, expandable }) => {
  return (
    <div className={cs([expandable ? "col-auto" : "col", "border p-1"])}>
      <div className="d-flex flex-column">
        <small className="text-muted text-small">{title}</small>
        <div className="">{value}</div>
      </div>
    </div>
  );
};

const FormHeaderCell = ({ title }) => {
  return (
    <div className="col d-flex align-items-center justify-content-center text-center p-1 bg-dark text-light">
      {title}
    </div>
  );
};

const ContractToPrint = (props) => {
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [didDownload, setDidDownload] = useState(false);

  const pullVehicle = async (vin) => {
    const VINFilter = {
      VIN: {
        eq: vin,
      },
    };

    let results = [];

    API.graphql({
      query: queries.listVehicles,
      variables: { filter: VINFilter, limit: 10000 },
    })
      .then((res) => {
        results = res.data.listVehicles.items;
      })
      .catch((res) => {
        results = res.data.listVehicles.items;
      })
      .finally(() => {
        console.log(results);
        if (results.length === 0) {
          props.history.replace("/customer");
        } else {
          const vehicle = results[0];
          setSelectedVehicle(vehicle);
        }
      });
  };

  useEffect(() => {
    const vin = props.match.params.vin;
    pullVehicle(vin);
  }, []);

  const triggerDownload = (toPdf) => {
    if (didDownload === false) {
      toPdf();
      setDidDownload(true);
    }
  };

  if (!selectedVehicle) {
    return <></>;
  }

  return (
    <Pdf filename={`${selectedVehicle?.owner?.name || "Your"}-Contract.pdf`}>
      {({ toPdf, targetRef }) => (
        <div style={{ zIndex: -500 }} onLoad={() => triggerDownload(toPdf)}>
          <div
            ref={targetRef}
            style={{
              width: "8.5in",
              height: "11in",
              backgroundColor: "white",
              padding: "1in",
              justifyContent: "center",
              paddingLeft: "-0.3in",
              paddingTop: "0.3in",
              paddingBottom: "0.3in",
            }}
          >
            <div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src={logo}
                  height={100}
                  alt="Elite Service Warranty Logo"
                />
                <h3 className="m-0">
                  Extended Service Warranty
                  <br /> for Recreational Vehicles
                </h3>
              </div>

              <div className="container border mt-3 w-100">
                <Row>
                  <FormCell
                    title="Seller/Dealer Name"
                    value={selectedVehicle?.contract?.dealer?.name || ""}
                  />
                  <FormCell
                    title="Application Date"
                    value={moment(selectedVehicle?.contract?.createdAt).format(
                      "MMM DD, YYYY"
                    )}
                  />
                </Row>
                <Row>
                  <FormCell
                    title="Customer Name"
                    value={selectedVehicle?.owner?.name}
                  />
                  <FormCell
                    title="Phone"
                    value={selectedVehicle?.owner?.phone}
                    expandable
                  />
                  <FormCell
                    title="Email"
                    value={selectedVehicle?.owner?.email}
                    expandable
                  />
                </Row>
                <Row>
                  <FormCell
                    title="Shipping Address"
                    value={
                      (selectedVehicle?.owner?.shippingStreetAddress || "") +
                      " " +
                      (selectedVehicle?.owner?.shippingStreetAddress2 || "")
                    }
                    expandable
                  />
                  <FormCell
                    title="City"
                    value={selectedVehicle?.owner?.shippingCity}
                  />
                  <FormCell
                    title="State"
                    value={selectedVehicle?.owner?.shippingState}
                  />
                  <FormCell
                    title="Zip"
                    value={selectedVehicle?.owner?.shippingZip}
                  />
                </Row>
                <Row>
                  <FormHeaderCell title="Recreational Vehicle Information" />
                </Row>
                <Row>
                  <FormCell
                    title="Recreational Vehicle Type"
                    value={selectedVehicle?.style}
                  />
                  <FormCell
                    title="Vehicle Year"
                    value={selectedVehicle?.vehicleYear}
                  />
                  <FormCell
                    title="Vehicle Make"
                    value={selectedVehicle?.make}
                    expandable
                  />
                  <FormCell
                    title="Vehicle Model"
                    value={selectedVehicle?.vehicleModel}
                  />
                </Row>
                <Row>
                  <FormCell
                    title="Chassis Model"
                    value={selectedVehicle?.chassisModel}
                  />
                  <FormCell title="Vehicle VIN" value={selectedVehicle?.VIN} />
                </Row>
                <Row>
                  <FormCell
                    title="Purchase Price"
                    value={
                      "$" + selectedVehicle?.purchasePrice?.toLocaleString()
                    }
                  />
                  <FormCell
                    title="Purchase Date"
                    value={selectedVehicle?.purchaseDate}
                    expandable
                  />
                  <FormCell
                    title="Lienholder"
                    value={selectedVehicle?.lienholder}
                  />
                </Row>
                <Row>
                  <FormHeaderCell title="Service Warranty Contract Information and Options" />
                </Row>
                <Row>
                  <FormCell
                    title="Contract"
                    value={selectedVehicle?.contract?.tier}
                  />
                  <FormCell
                    title="Vehicle New/Used"
                    value={
                      (selectedVehicle?.contract?.typeNew ? "New" : "Used") +
                      " vehicle"
                    }
                  />
                  <FormCell
                    title="Deductible"
                    value={"$" + selectedVehicle?.contract?.deductible}
                  />
                </Row>
                <Row>
                  <FormCell
                    title="Term length"
                    value={selectedVehicle?.contract?.termLength + " months"}
                  />
                  <FormCell
                    title="Service contract price"
                    value={"$" + selectedVehicle?.contract?.contractPrice}
                  />

                  <FormCell
                    title="Senior Citizen/Military"
                    value={
                      selectedVehicle?.contract?.seniorCitizenMilitary
                        ? "Yes"
                        : "No"
                    }
                  />
                </Row>
                {selectedVehicle?.contract?.typeNew && (
                  <>
                    <Row>
                      <FormHeaderCell title="Warranty Information" />
                    </Row>
                    <Row>
                      <FormCell
                        title="Original In-Service Date"
                        value={selectedVehicle?.contract?.originalInServiceDate}
                      />
                      <FormCell
                        title="Original Warranty Length"
                        value={
                          selectedVehicle?.contract?.originalWarrantyMonths +
                          " months"
                        }
                      />
                    </Row>
                  </>
                )}
              </div>

              <small className="text-muted mt-3 d-block">
                Customer has reviewed a copy of the Service Warranty Contract.
                This contract outlines the coverage that is afforded to you with
                your service warranty. For any questions about your coverage,
                please contact (866) 862-7164, or email
                <a href="mailto:customersupport@eliteservicewarranty.com">
                  {" "}
                  customersupport@eliteservicewarranty.com
                </a>
                .
              </small>

              <div className="d-flex align-items-center mt-3">
                <div>Customer Signature: </div>
                <div className="mx-2">
                  <img
                    src={selectedVehicle?.contract?.customerSignature}
                    style={{ maxHeight: "100px" }}
                    alt="Customer Signature"
                  />
                </div>
                <div>
                  Date: &nbsp;
                  {moment(selectedVehicle?.contract?.createdAt).format(
                    "MMM DD, YYYY"
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mt-2">
                <div>Dealer Signature: </div>
                <div className="mx-2">
                  <img
                    src={selectedVehicle?.contract?.dealerSignature}
                    style={{ maxHeight: "100px" }}
                    alt="Dealer Signature"
                  />
                </div>
                <div>
                  Date: &nbsp;
                  {moment(selectedVehicle?.contract?.createdAt).format(
                    "MMM DD, YYYY"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Pdf>
  );
};

export default ContractToPrint;
